<template>
	<div id="introduce" v-if="menus[0]" v-html="menus[0].menuText">
		<!-- 项目介绍 -->
	</div>
</template>

<script>
	import {
		ProjectPage // 优秀稿件
	} from '@/request/api';
	export default {
		name: 'introduce',
		data() {
			return {
				menus: ''
			}
		},
		created() {
			this.getProjectPage();
		},
		mounted() {},
		methods: {
			getProjectPage() {
				ProjectPage().then(res => {
					if (res.code == 200) {
						this.menus = res.data.menus
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#introduce {
		* {
			text-align: justify;
		}

		h3 {
			font-weight: bold;
			margin-bottom: 10px;
			font-size: 18px;
		}

		p {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
</style>